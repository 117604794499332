import React from 'react';
import { motion } from 'framer-motion';

const GatsbyIcon = ({ scale }) => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="60"
      animate={{ opacity: 1, scale: scale }}
      initial={{ opacity: 0 }}
    >
      <motion.path
        animate={{ pathLength: 1, transition: { duration: 1 } }}
        initial={{ pathLength: 0 }}
        d="M70.63 27.75h2.8v10h-2.8v-1.3c-1 1.5-2.3 1.6-3.1 1.6-3.1 0-5.1-2.4-5.1-5.3 0-3 2-5.3 4.9-5.3.8 0 2.3.1 3.2 1.6v-1.3zm-5.2 5c0 1.6 1.1 2.8 2.8 2.8a2.73 2.73 0 0 0 2.8-2.8c0-1.6-1.1-2.8-2.8-2.8a2.73 2.73 0 0 0-2.8 2.8zm13.5-2.6v7.6h-2.8v-7.6h-1.1v-2.4h1.1v-3.4h2.8v3.4h1.9v2.4zm8.5 0c-.7-.6-1.3-.7-1.6-.7-.7 0-1.1.3-1.1.8 0 .3.1.6.9.9l.7.2c.8.3 2 .6 2.5 1.4.3.4.5 1 .5 1.7 0 .9-.3 1.8-1.1 2.5s-1.8 1.1-3 1.1c-2.1 0-3.2-1-3.9-1.7l1.5-1.7c.6.6 1.4 1.2 2.2 1.2s1.4-.4 1.4-1.1c0-.6-.5-.9-.9-1l-.6-.2c-.7-.3-1.5-.6-2.1-1.2-.5-.5-.8-1.1-.8-1.9 0-1 .5-1.8 1-2.3.8-.6 1.8-.7 2.6-.7.7 0 1.9.1 3.2 1.1zm6.1-1.1c1-1.4 2.4-1.6 3.2-1.6 2.9 0 4.9 2.3 4.9 5.3s-2 5.3-5 5.3c-.6 0-2.1-.1-3.2-1.6v1.3h-2.7v-16.8h2.8zm-.3 3.7c0 1.6 1.1 2.8 2.8 2.8a2.73 2.73 0 0 0 2.8-2.8c0-1.6-1.1-2.8-2.8-2.8s-2.8 1.2-2.8 2.8zm13 3.5l-4.8-8.5h3.3l3.1 5.7 2.8-5.7h3.2l-8 15.3h-3.2zm-44.5-6.8h-7v2.8h3.7c-.6 1.9-2 3.2-4.6 3.2-2.9 0-5-2.4-5-5.3s2-5.4 4.9-5.4c1.6 0 3.2.8 4.2 2.1l2.3-1.5c-1.5-2.1-3.9-3.3-6.5-3.3-4.4 0-8 3.6-8 8.1s3.4 8.1 8 8.1 8-3.6 8-8.1c.1-.3 0-.5 0-.7z"
      />
      <motion.path
        animate={{ pathLength: 1, transition: { duration: 1 } }}
        initial={{ pathLength: 0 }}
        d="M36.752 30H28.19v2.447h5.872c-.856 3.67-3.548 6.728-7.095 7.952L12.897 26.33C14.365 22.048 18.524 19 23.295 19c3.67 0 6.973 1.835 9.053 4.65l1.835-1.6c-2.447-3.303-6.36-5.505-10.888-5.505-6.36 0-11.744 4.526-13.1 10.52l16.148 16.148C32.226 41.744 36.752 36.36 36.752 30zm-26.914.122c0 3.425 1.346 6.728 3.915 9.297s5.994 3.915 9.297 3.915z"
        fill="#fff"
      />
      <motion.path
        animate={{ pathLength: 1, transition: { duration: 1 } }}
        initial={{ pathLength: 0 }}
        d="M23.295 12.873c-9.42 0-17.127 7.707-17.127 17.127s7.707 17.127 17.127 17.127S40.422 39.42 40.422 30s-7.707-17.127-17.127-17.127zm-9.542 26.67c-2.57-2.57-3.915-5.994-3.915-9.297l13.334 13.212c-3.425-.122-6.85-1.346-9.42-3.915zM26.23 43.1L10.205 27.064c1.346-5.994 6.728-10.52 13.1-10.52 4.526 0 8.44 2.202 10.888 5.505l-1.835 1.6c-2.08-2.814-5.383-4.65-9.053-4.65-4.77 0-8.808 3.058-10.398 7.34l14.068 14.068c3.548-1.223 6.24-4.282 7.095-7.952H28.19V30h8.563c0 6.36-4.526 11.744-10.52 13.1z"
        fill="#639"
      />
    </motion.svg>
  );
};

export default GatsbyIcon;
