import React from 'react';
import { motion } from 'framer-motion';

const ApolloIcon = ({ scale }) => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="60"
      animate={{ opacity: 1, scale: scale }}
      initial={{ opacity: 0 }}
    >
      <motion.path
        animate={{ pathLength: 1, transition: { duration: 1 } }}
        initial={{ pathLength: 0 }}
        d="M22.602 22.954h-3.33L14.46 35.44h3.015l.786-2.115h4.546l-.823-2.34h-2.997l1.947-5.373L24.4 35.44h3.014zM73.22 35.44V22.954h2.677V33.1h5.278v2.34H73.22zm15.43 0V22.954h2.677V33.1h5.28v2.34H88.65zM58.395 25.2a4.02 4.02 0 0 1 4.015 4.015 4.02 4.02 0 0 1-4.015 4.015 4.02 4.02 0 0 1-4.015-4.015 4.02 4.02 0 0 1 4.015-4.015m0-2.443a6.46 6.46 0 0 0-6.458 6.458 6.46 6.46 0 0 0 6.458 6.459 6.46 6.46 0 0 0 6.459-6.459 6.46 6.46 0 0 0-6.459-6.458zm50.517 2.443a4.02 4.02 0 0 1 4.015 4.015 4.02 4.02 0 0 1-4.015 4.015 4.02 4.02 0 0 1-4.016-4.015 4.02 4.02 0 0 1 4.016-4.015m0-2.443a6.46 6.46 0 0 0-6.459 6.458 6.46 6.46 0 0 0 6.459 6.459 6.46 6.46 0 0 0 6.459-6.459 6.46 6.46 0 0 0-6.459-6.458zm-67.888.206H35.2V35.44h2.66v-4.297h3.164c2.22 0 4.018-1.876 4.018-4.095s-1.8-4.094-4.018-4.094zm0 5.745H37.86v-3.303h3.164c.868 0 1.574.78 1.574 1.65s-.706 1.65-1.574 1.65zm-8.118 10.53a.67.67 0 0 0-.56.3l-.923 1.008c-1.368 1.368-2.96 2.44-4.733 3.2a14.79 14.79 0 0 1-5.795 1.17c-2.012 0-3.96-.393-5.795-1.17a14.82 14.82 0 0 1-4.733-3.19 14.85 14.85 0 0 1-3.192-4.733A14.93 14.93 0 0 1 6.005 30a14.8 14.8 0 0 1 1.17-5.795 14.84 14.84 0 0 1 3.192-4.733c1.368-1.368 2.962-2.44 4.733-3.192a14.79 14.79 0 0 1 5.795-1.17c2.012 0 3.96.393 5.795 1.17a14.8 14.8 0 0 1 3.569 2.144c-.06.175-.1.358-.1.543a1.68 1.68 0 1 0 1.68-1.68 1.67 1.67 0 0 0-.65.131c-2.805-2.3-6.393-3.68-10.304-3.68C11.9 13.736 4.63 21.017 4.63 30s7.282 16.264 16.264 16.264c5.025 0 9.515-2.28 12.498-5.86.124-.124.2-.296.2-.486a.69.69 0 0 0-.688-.688z"
        fill="#333"
      />
    </motion.svg>
  );
};

export default ApolloIcon;
