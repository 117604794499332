import React from 'react';
import { motion } from 'framer-motion';

const JavaIcon = ({ scale }) => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="60"
      animate={{ opacity: 1, scale: scale }}
      initial={{ opacity: 1 }}
    >
      <motion.path
        animate={{ pathLength: 1, transition: { duration: 1 } }}
        initial={{ pathLength: 0 }}
        d="M17.956 42.562s-1.767 1.075 1.23 1.383c3.6.46 5.53.384 9.524-.384 0 0 1.075.69 2.535 1.23-8.987 3.84-20.354-.23-13.288-2.227zm-1.152-4.992s-1.92 1.46 1.075 1.767c3.917.384 7 .46 12.29-.614 0 0 .69.768 1.843 1.152-10.83 3.226-22.966.307-15.208-2.304zm21.2 8.756s1.306 1.075-1.46 1.92c-5.146 1.536-21.583 1.997-26.192 0-1.613-.69 1.46-1.7 2.458-1.843 1-.23 1.536-.23 1.536-.23-1.766-1.23-11.752 2.535-5.07 3.6 18.357 2.995 33.488-1.306 28.726-3.456zM18.8 32.347s-8.372 1.997-2.996 2.688c2.304.307 6.836.23 11.06-.077 3.456-.307 6.913-.922 6.913-.922s-1.23.538-2.074 1.075c-8.526 2.227-24.886 1.23-20.2-1.075 3.994-1.92 7.297-1.7 7.297-1.7zm14.978 8.372c8.602-4.455 4.608-8.756 1.843-8.218-.69.154-1 .307-1 .307s.23-.46.768-.614c5.453-1.92 9.755 5.76-1.767 8.756 0 0 .077-.077.154-.23zM19.646 52.854c8.295.538 20.97-.307 21.276-4.224 0 0-.614 1.536-6.836 2.688-7.066 1.306-15.822 1.152-20.97.307 0 0 1.075.922 6.53 1.23z"
        fill="#4e7896"
      />
      <motion.path
        animate={{ pathLength: 1, transition: { duration: 1 } }}
        initial={{ pathLength: 0 }}
        d="M28.555 7s4.762 4.84-4.532 12.136c-7.45 5.914-1.7 9.294 0 13.134-4.378-3.917-7.527-7.373-5.377-10.6 3.15-4.762 11.828-7.066 9.908-14.67zm-2.458 22.044c2.227 2.535-.615 4.84-.615 4.84s5.684-2.92 3.072-6.53c-2.38-3.456-4.224-5.146 5.76-10.907 0 0-15.746 3.917-8.218 12.596z"
        fill="#f58219"
      />
      <motion.path
        animate={{ pathLength: 1, transition: { duration: 1 } }}
        initial={{ pathLength: 0 }}
        d="M75.318 38.162V26.485c0-2.98-1.668-5.004-5.838-5.004-2.383 0-4.41.596-6.196 1.31l.596 2.145c1.31-.477 2.98-.953 4.766-.953 2.264 0 3.336.953 3.336 2.86v1.55H70.8c-5.72 0-8.34 2.145-8.34 5.6 0 2.86 1.787 4.528 5.004 4.528 2.026 0 3.575-.953 5.004-2.145l.238 1.787h2.62zM71.98 34.35c-1.19 1.072-2.502 1.668-3.694 1.668-1.55 0-2.502-.953-2.502-2.62s.953-2.86 4.766-2.86h1.43v3.813zm14.536 3.813h-4.17L77.343 21.84h3.575l3.098 10.01.715 2.98c1.55-4.29 2.74-8.698 3.217-12.987h3.574c-.953 5.362-2.62 11.2-5.004 16.323zm19.302 0V26.485c0-2.98-1.668-5.004-5.84-5.004-2.383 0-4.408.596-6.196 1.31l.477 2.145c1.43-.477 3.098-.953 4.885-.953 2.264 0 3.336.953 3.336 2.86v1.55h-1.19c-5.72 0-8.34 2.145-8.34 5.6 0 2.86 1.668 4.528 4.885 4.528 2.145 0 3.694-.953 5.123-2.145l.238 1.787h2.62zm-3.336-3.813c-1.19 1.072-2.502 1.668-3.694 1.668-1.55 0-2.502-.953-2.502-2.62s.953-2.86 4.766-2.86h1.43v3.813zm-43.728 6.553c-.953 1.43-2.383 2.502-4.17 3.098l-1.55-1.906c1.192-.715 2.383-1.787 2.86-2.74.477-.834.596-2.026.596-4.766V16h3.575v18.35c0 3.694-.358 5.123-1.31 6.553z"
        fill="#4e7896"
      />
    </motion.svg>
  );
};

export default JavaIcon;
